import React, { useEffect, useState } from "react";
import { Col, Container, Form, FormGroup, Row, Input } from "reactstrap";

import { AiOutlineRight } from "react-icons/ai";
import { useSelector, useDispatch } from "react-redux";
import { shippingCost } from "../../redux/slices/settingSlice";
import axiosClient from "../../axios-client";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";


const OrderShippingAddress = (props) => {

  const navigate = useNavigate();
  const user = localStorage.getItem('USER_DATA');
    const [emailShippingRef, setemailShippingRef] = useState('');
    const [first_nameShippingRef, setfirst_nameShippingRef] = useState('');
    const [last_nameShippingRef, setlast_nameShippingRef] = useState('');
    const [addressShippingRef, setaddressShippingRef] = useState('');
    const [countryShippingRef, setcountryShippingRef] = useState('');
    const [cityShippingRef, setcityShippingRef] = useState('');
    const [stateShippingRef, setstateShippingRef] = useState('');
    const [zipShippingRef, setzipShippingRef] = useState('');
    const [addressBillingRef, setaddressBillingRef] = useState('');
    const [countryBillingRef, setcountryBillingRef] = useState('');
    const [cityBillingRef, setcityBillingRef] = useState('');
    const [stateBillingRef, setstateBillingRef] = useState('');
    const [zipBillingRef, setzipBillingRef] = useState('');
    const [shippingPhone, setshippingPhone] = useState('');
  
    const [billingPhone, setbillingPhone] = useState('');
    const [firstnameBillingRef, setfirstnameBillingRef] = useState('');
    const [lastnameBillingRef, setlastnameBillingRef] = useState('');
    const [emailBillingingRef, setemailBillingRef] = useState('');
  
    const [countryList, setCountryList] = useState([]);
    const [userAddressData, setUserAddressData] = useState([]);
  
  
    const [districtList, setdistrictList] = useState([]);
    const [divisionShippingRef, setDivisionShippingRef] = useState('');
    const [divisionList, setdivisionList] = useState([]);
  
    const [districtShippingRef, setDistrictShippingRef] = useState('');
  
    const dispatch = useDispatch();

    const { t } = useTranslation()
  
  
  
    const userInfodata = {
      name: "",
      email: "",
      phone: "",
      address: "",
      city: "",
      postal_code: "",
      country: "",
      division: "",
      district: "",
    };
  
    const [userInfo, setUserInfo] = useState(userInfodata);

    // Function to fetch country data from REST Countries API
    async function fetchCountryData(countryName) {
      const username = 'reon63'; // Replace with your GeoNames username
      try {
          const response = await fetch(`http://secure.geonames.org/countryInfoJSON?country=${countryName}&username=${username}`);
          const data = await response.json();
          return data.geonames[0]; // Assuming the first result is the correct country
      } catch (error) {
          console.error('Error fetching country data:', error);
          throw error;
      }
  }

  // Function to fetch divisions (states/provinces) from GeoNames API
  async function fetchChildren(code) {
      const username = 'reon63'; // Replace with your GeoNames username
      try {
          const response = await fetch(`https://secure.geonames.org/childrenJSON?geonameId=${code}&username=${username}`);
          const data = await response.json();
          return data.geonames; // Assuming the divisions are in the 'geonames' array
      } catch (error) {
          console.error('Error fetching divisions:', error);
          throw error;
      }
  }

  // Example usage
  async function getDivisions(countryName) {
      try {
          // Fetch country data
          const countryData = await fetchCountryData(countryName);
          // Extract country code
          const countryCode = countryData.geonameId;
          
          // Fetch divisions for the country
          const divisions = await fetchChildren(countryCode);

          if(divisions) {
              return divisions;
          }
      } catch (error) {
          console.error('An error occurred:', error);
      }
  }

  async function getDistricts(divisionCode) {
      try {
          // Fetch districts for the country
          const districts = await fetchChildren(divisionCode);

          if(districts) {
              return districts;
          }
      } catch (error) {
          console.error('An error occurred:', error);
      }
  }
  
    useEffect(() => {
  
      axiosClient.get("country/list").then(({ data }) => {
        setCountryList(data)
      });
      
      axiosClient.get(`user/shipping/billing/address/get?userId=${JSON.parse(user).id}`).then(({ data }) => {
        if(data.data){
          setUserAddressData(data.data);
          setemailShippingRef(data.data.email);
          setfirst_nameShippingRef(data.data.first_name);
          setlast_nameShippingRef(data.data.last_name);
          setaddressShippingRef(data.data.shipping_address);
          setcountryShippingRef(data.data.shipping_country);
          setcityShippingRef(data.data.shipping_city);
          setstateShippingRef(data.data.shipping_state);
          setzipShippingRef(data.data.shipping_zip);
          setaddressBillingRef(data.data.billing_address);
          setcountryBillingRef(data.data.billing_country);
          setcityBillingRef(data.data.billing_city);
          setstateBillingRef(data.data.billing_state);
          setzipBillingRef(data.data.billing_zip);
          setshippingPhone(data.data.shipping_phone);
          setbillingPhone(data.data.billing_phone);
          setfirstnameBillingRef(data.data.billing_first_name);
          setlastnameBillingRef(data.data.billing_last_name);
          setemailBillingRef(data.data.billing_email);
          if (data.data.shipping_country) getDivisionList(data.data.shipping_country)
          if (data.data.shipping_division) getDistrictList(data.data.shipping_division)
          if (data.data.shipping_district) setDistrictShippingRef(data.data.shipping_district)
              
          setUserInfo({
            name: `${data.data.first_name} ${data.data.last_name}`,
            email: `${data.data.email}`,
            phone: `${data.data.shipping_phone}`,
            address: `${data.data.shipping_address}`,
            city: `${data.data.shipping_city}`,
            postal_code: `${data.data.shipping_zip}`,
            country: `${data.data.shipping_country}`,
            division: `${data.data.shipping_division == null ? 0 : data.data.shipping_division}`,
            district: `${data.data.shipping_district == null ? 0 : data.data.shipping_district}`,
          })
          
          dispatch(shippingCost({ divisionId: data.data.shipping_division, districtId: data.data.shipping_district}))
        }
      }).catch((err) => {
        console.error(err);
      });
    }, [])
  

    const getDivisionList = async (countryCode) => {
      const response = await getDivisions(countryCode);
      if (response && countryCode) {
        setdivisionList(response);
      }
    }
  
    let getDistrictList = async (divisionId) => {
      const response = await getDistricts(divisionId);
      if(divisionId && response) {
        setdistrictList(response)
        setDivisionShippingRef(divisionId)
      }
    }
  
    let onchangeDivision = (event) => {
      getDistrictList(event.target.value)
      inputdata(event)
    }
    let onChangeDistrict = (event) => {
      setDistrictShippingRef(event.target.value);
      inputdata(event);
      dispatch(shippingCost({ divisionId: userInfo.division, districtId: event.target.value }))
  
    }
  
  
    const [infoRequired, setinfoRequired] = useState(userInfodata);
    const inputdata = async (e) => {
      let name = e.target.name;
      let dataValue = e.target.value;
      setTimeout(() => {
        if(name == 'country') getDivisionList(e.target.value);
      }, 0.3 * 1000)
      setUserInfo((prev) => ({ ...prev, [name]: dataValue }));
    };
    const placeOrder = () => {
      setinfoRequired(userInfodata);
      var isBreak = 0;
      const petList = Object.entries(userInfo).map(([key, value]) => {
        if (!value || value == 0) {
          value = "empty";
          isBreak = 1;
        }
        setinfoRequired((prev) => ({ ...prev, [key]: value }));
      });
      if (isBreak) {
        toast.error(t("set_your_shipping_address"));
        navigate('/user/deshboard/2');
        return false;
      }
      props.address(userInfo);
    };
  
    return <>
  
      <h6 className="mb-1 pb-3 checkout__txt "> {t("shipping_address")}</h6>
  
      <Form className="billing__form userInfoUse">
        <FormGroup className="form__group inputbasehulf mb-0">
          {/* https://tm-shopify076-clothes.myshopify.com/cart */}
  
          <h6 className="infoname__st"> {t("name")} </h6>
          <Input
            type="text"
            name="name"
            defaultValue={userInfo.name}
            placeholder={t("enter_your_name")}
            onChange={inputdata}
            readOnly
          />
          {infoRequired.name == "empty" && (
            <span className="error__tx">{t("name_is_required")}</span>
          )}
        </FormGroup>
        <FormGroup className="form__group inputbasehulf">
          <h6 className=" infoname__st">{t("email")}</h6>
          <Input
            defaultValue={userInfo.email}
            type="email"
            name="email"
            placeholder={t("enter_your_email")}
            onChange={inputdata}
            readOnly
          />
          {infoRequired.email == "empty" && (
            <span className="error__tx">{t("email_is_required")}</span>
          )}
        </FormGroup>
        <FormGroup className="form__group inputbasehulf">
          <h6 className="infoname__st">{t("phone_number")} </h6>
          <Input
            defaultValue={userInfo.phone}
            type="number"
            name="phone"
            placeholder={t("enter_your_number")}
            onChange={inputdata}
            readOnly
          />
          {infoRequired.phone == "empty" && (
            <span className="error__tx">{t("phone_is_required")}</span>
          )}
        </FormGroup>
        <FormGroup className="form__group inputbasehulf">
          <h6 className="infoname__st">{t("address")} </h6>
          <Input
            defaultValue={userInfo.address}
            type="text"
            name="address"
            placeholder={t("street_address")}
            onChange={inputdata}
            readOnly
          />
          {infoRequired.address == "empty" && (
            <span className="error__tx">{t("address_is_required")}</span>
          )}
        </FormGroup>
   
  
        <FormGroup className="form__group inputbasehulf">
          <h6 className="infoname__st">{t("country")} </h6>
  
  
          <Input type="select" name="country" id="exampleSelect" onChange={inputdata} placeholder={t("country")} readOnly>
            <option value="0">{t("select_country")}</option>
            {
              Object.keys(countryList).map((data, index) => {
                return <option value={data} key={data} selected={data == userInfo.country ? true : false}>{countryList[data].name}</option>
              })
            }
          </Input>
  
          {infoRequired.country == "empty" && (
            <span className="error__tx">{t("country_is_required")}</span>
          )}
        </FormGroup>
        <FormGroup className="form__group inputbasehulf">
          <h6 className="infoname__st">{t("division")} </h6>
  
  
          <Input type="select" name="division" id="exampleSelect" onChange={onchangeDivision} placeholder={t("division")}>
            <option value="0">{t("select_division")}</option>
            {
              divisionList.length > 0 && divisionList.map((data, index) => {
                return <option value={data.geonameId} key={data.geonameId} selected={data.geonameId == divisionShippingRef ? true : false}>{data.name}</option>
              })
            }
  
          </Input>
  
  
          {infoRequired.division == "empty" && (
            <span className="error__tx">{t("division_is_required")}</span>
          )}
        </FormGroup>
        <FormGroup className="form__group inputbasehulf">
          <h6 className="infoname__st">{t("district")} </h6>
  
  
  
          <Input type="select" name="district" id="exampleSelect" onChange={onChangeDistrict} placeholder={t("district")}>
            <option value="0">{t("select_district")}</option>
            {
              districtList.length > 0 && districtList.map((data, index) => {
                return <option value={data.geonameId} key={data.geonameId} selected={data.geonameId == districtShippingRef ? true : false}>{data.name}</option>
              })
            }
  
          </Input>
  
  
  
          {infoRequired.district == "empty" && (
            <span className="error__tx">{t("district_is_required")}</span>
          )}
        </FormGroup>
  
        <FormGroup className=" form__group inputbasehulf">
          <h6 className="infoname__st">{t("postal_code")} </h6>
          <Input
            type="text"
            defaultValue={userInfo.postal_code}
            name="postal_code"
            placeholder={t("postal_code")}
            onChange={inputdata}
            readOnly
          />
          {infoRequired.postal_code == "empty" && (
            <span className="error__tx">{t("postal_code_is_required")}</span>
          )}
        </FormGroup>
  
      </Form>
      <div>
        <button
          className="buy__btn auth__btn w-100"
          onClick={placeOrder}
        >
          {t("shipping")}
        </button>
      </div>
    </>
  
  }

  export default OrderShippingAddress;